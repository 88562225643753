import React from 'react';

const Promotion = () => {

    return <div className="fh5co-box animate-bo bg-color" style={{ 'background': 'url(images/planner.png) no-repeat center left', borderRadius: '15px' }}>

        <div className="text-right">

        </div>

        <h2 className="color-change-head">Sign up here and explore.</h2>
        <p className="margin-main-text">.</p>
        <p className="margin-button"><a href="https://booking-saas-portal-view.pages.dev/authO/register" target="_blank" className="btn btn-primary with-arrow">Sign up here<i className="icon-arrow-right"></i></a></p>

    </div>

}

export const HowDoesItWork = () => {

    return <div className="fh5co-box animate-bo bg-color" style={{ borderRadius: '15px', marginRight: "75rem" }}>

        <div className="text-center">
            How does it work,
        </div>
        <br />
        <div className="text-center">
            When an API key and room are created, the standalone widget or calendar scripts are added to a website or application that supports JavaScript/HTML/CSS.
        </div>
        <br />

        <div className="text-center">
            In the created account, the option 'Check appointments' from the Actions button is for displaying the calendar events created by the widget or the calendar script that your clients or users are using on your site. For the widget and calendar, it will use the browser language.</div>
        <br />

        <div className="text-center">
            The widget and the calendar do not collect any information from the users or the application. They only add hCaptcha as an external third-party service, except for the one from the CDN script, which is for fraud/bot prevention.</div>

        {/* <h2 className="color-change-head">Sign up here and explore.</h2> */}
        <p className="margin-main-text">.</p>
        <p className="margin-button"><a href="https://booking-saas-portal-view.pages.dev/authO/register" target="_blank" className="btn btn-primary with-arrow">Sign up here<i className="icon-arrow-right"></i></a></p>

    </div>

}

export default Promotion;