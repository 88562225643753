import React, { useState, Component } from 'react';
import './style.css';
import Promotion, { HowDoesItWork } from './Promotion';
import PromoContainer from './PromoContainer';
import HeaderTextComp from './header-no-promotion-comp/HeaderTextComp';
import PromotionLoader from './PromotionLoader/PromotionLoader';
import GetQuery from '../../QueryService/GetQuery/GetQuery';
import PromotionsHead from './PromotionsHead';

export default class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            promo: true,
            loading: false
        }
    }

    // componentDidMount() {
    //     this.getPromos();
    // }

    initDemo() {
        const script = document.createElement('script');
        script.src = "https://booking-saas-portal-view.pages.dev/cdn_script/booking-cdn.js";
        script.setAttribute("apiKey", "476949831C474FF3EC984D548806144C7F1F1DCA46686424");
        script.setAttribute("roomName", "tests0.03979339158946327");
        script.async = true;
        document.body.appendChild(script);
    }

    getPromos = async () => {
        const query = new GetQuery();
        const result = await query.getPromotions();
        //console.log(await result.error);
        if (await result.error) {
            setTimeout(() => {
                this.setState({
                    loading: false,
                    promo: false,
                    error: result.error
                });
            }, 500);
        } else {
            setTimeout(() => {
                this.setState({
                    loading: false,
                    promo: true,
                    data: result
                });
            }, 500)
        }
    }

    //promoHandler = () => {
    //    this.setState({
    //        promo: !this.state.promo
    //    })
    //}

    render() {
        //if (this.state.data) {
        //    console.log(this.state.data[0]);
        //    console.log(this.state.data[0].promotion_status);
        //    console.log(this.state.data[0].tittle);

        //}

        return (
            <div id="fh5co-page">
                {/*<button className="btn btn-warning" onClick={this.promoHandler}>Test the promotion</button>*/}


                <div className="fh5co-page-title" style={{ 'background': 'url(images/notebook.jpg)', 'height': '850px' }} id="mobile-head">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 animate-box">
                                <h1 className="text-left"><span className="colored">Welcome to </span> B<span className="colored">SAAS</span></h1>
                            </div>

                            <div className="animate-box head-text-seccond" style={{ 'marginTop': '150px' }}>
                                <HeaderTextComp />
                            </div>
                        </div>

                    </div>
                </div>

                {/*<div className="header-no-promotion img-responsive home-image-poster animate-bo">

                    <HeaderTextComp />

                </div>*/}



                <div className="mt-5">

                </div>

                {/*loading the promotion*/}

                <div className="fh5co-section animate-box">
                    <PromotionsHead text="Our services" />
                </div>

                <div className="fh5co-section-with-image animate-box">

                    <img src="images/main-goal.jpg" alt="" className="img-responsive home-image-poster" />

                    <Promotion />

                </div>


                <div className="mt-5">

                </div>

                {/*loading the promotion*/}

                <div className="fh5co-section animate-box">
                    <PromotionsHead text="How does it work" />
                </div>

                <div className="fh5co-section-with-image animate-box">

                    <img src="images/main-goal.jpg" alt="" className="img-responsive home-image-poster" />

                    <HowDoesItWork />
                </div>

                {/*promotion container holder*/}
                <PromoContainer>
                    Our services
                </PromoContainer>

            </div>
        )
    }
}