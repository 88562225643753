import axios from 'axios';
import React, { Component } from 'react';

export default class Contact extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sended: false,
            buttonPresed: false
        }
    }

    resetErrors() {
        let object = {
            Name: '',
            Email: '',
            Subject: '',
            Content: ''
        };

        for (const key in object) {
            if (Object.hasOwnProperty.call(object, key)) {
                const element = object[key];
                let htmlElement = document.getElementById(`${key}`);
                htmlElement.innerHTML = element[0] || '';
            }
        }
    }

    async onSubmit(e) {
        e.preventDefault();
        this.resetErrors();
        this.setState({
            buttonPresed: true,
        })

        const name = e.target.name.value;
        const email = e.target.email.value;
        const subject = e.target.subject.value;
        const message = e.target.message.value;

        const payload = {
            name,
            email,
            subject,
            content: message
        };

        const url = 'https://booking-saas-auth.onrender.com/api/Contact';
        await axios.post(url, payload)
            .then(response => {
                this.setState({
                    sended: true,
                    buttonPresed: false,
                });
            })
            .catch(error => {

                let errors = error.response && (
                    error.response.data.message
                    || error.response.data
                    || error.response.statusText);

                if (errors) {
                    console.log(errors?.errors)
                    let object = errors?.errors;

                    for (const key in object) {
                        if (Object.hasOwnProperty.call(object, key)) {
                            const element = object[key];
                            let htmlElement = document.getElementById(`${key}`);
                            htmlElement.innerHTML = element[0];
                        }
                    }
                }
                this.setState({
                    buttonPresed: false,
                })
            })

        this.setState({
            buttonPresed: false,
        })
    }

    render() {
        return (
            <div>


                <div className="fh5co-page-title text-left" style={{ 'background': 'url(images/email2.png) no-repeat center' }}>
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 animate-box">
                                <h1><span className="colored">Contact</span> us</h1>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="fh5co-contact animate-box">

                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <h3>Contact.</h3>
                                <ul className="contact-info">
                                    <li><i className="icon-map"></i>.</li>

                                    <li><i className="icon-envelope"></i><a href="#">.</a></li>
                                    <li><i className="icon-globe"></i><a href="#">.</a></li>
                                </ul>
                            </div>
                            <div className="col-md-8 col-md-push-1 col-sm-12 col-sm-push-0 col-xs-12 col-xs-push-0">

                                <form id="email-form" onSubmit={this.onSubmit.bind(this)} className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <span id="Name" className="text-danger"></span>
                                            <input className="form-control" placeholder="Name" id="name" name="name" type="text" /><span className="text-danger" id="nameError"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <span id="Email" className="text-danger"></span>
                                            <input className="form-control" placeholder="Email" id="email" name="email" type="text" /><span className="text-danger" id="emailError"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <span id="Subject" className="text-danger"></span>
                                            <input className="form-control" placeholder="Subject" id="subject" name="subject" type="text" /><span className="text-danger" id="subjectError"></span>

                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <span id="Content" className="text-danger"></span>
                                            <textarea name="message" className="form-control" id="content" cols="30" rows="7" placeholder="Message"></textarea><span className="text-danger" id="messageError"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group" id="sendMailButton">

                                            {
                                                !this.state.sended &&
                                                !this.state.buttonPresed &&
                                                <input value="Send Message" className="btn btn-primary" onclick="validate()" type="submit" />
                                            }

                                            {
                                                !this.state.sended &&
                                                this.state.buttonPresed &&
                                                <div
                                                    className="btn btn-warning"
                                                    >
                                                    Loading...
                                                </div>
                                            }

                                            {
                                                this.state.sended &&
                                                <div className="btn btn-success">
                                                    Message sent successfully.
                                                            </div>
                                            }


                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

                {/* <div id="map" className="animate-box" data-animate-effect="fadeIn"></div> */}


            </div>
        )
    }
}