import React, { Component } from 'react';

export default class PromotionList extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        return (
            <>
            <div className="col-md-4 animate-box" data-animate-effect="fadeIn">
                <a className="fh5co-entry" href="https://booking-saas-portal-view.pages.dev/authO/register" target="_blank" >
                    <figure className="text-center">
                        <img src="./images/free-plan.jpg" alt="" className="img-responsive" />
                    </figure>
                    <div className="fh5co-copy">
                        <h3 className="margin-main-text" style={{'color':'white'}}>Sign Up</h3>
                        {/* <span className="fh5co-date">for Free </span> */}
                        <p>and explore the saas service
                        <br/> with CDN examples and integration.
                        </p>
                    </div>
                </a>
            </div>
{/* 
            <div className="col-md-4 animate-box" data-animate-effect="fadeIn">
                <a className="fh5co-entry" href="https://bookingsaas.tk/authO/login" target="_blank" >
                    <figure >
                        <img src="./images/basic.jpg" alt="" className="img-responsive" />
                    </figure>
                    <div className="fh5co-copy">
                        <h3 className="margin-main-text" style={{'color':'white'}}>Sign Up</h3>
                        <span className="fh5co-date">for Basic Plan</span>
                        <p>3.00 USD per month, the quote is up to 3 api keys and up to 3 rooms per key.</p>
                    </div>
                </a>
            </div>

            <div className="col-md-4 animate-box" data-animate-effect="fadeIn">
                <a className="fh5co-entry" href="https://bookingsaas.tk/authO/login" target="_blank" >
                    <figure >
                        <img src="./images/advanced.jpg" alt="" className="img-responsive" />
                    </figure>
                    <div className="fh5co-copy">
                        <h3 className="margin-main-text" style={{'color':'white'}}>Sign Up</h3>
                        <span className="fh5co-date">for Advanced Plan</span>
                        <p>6.00 USD per month, the quote is up 5 api keys and 5 rooms per key.</p>
                    </div>
                </a>
            </div> */}

            </>
            )
    }
}