import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";
import Error from './ErrorPage/Error';
import Home from './Pages/Home/Home';
import Layout from './Layout';
import NotFound from './Pages/NotFoundPage/NotFount';
import RegisterCode from './Pages/RegisterCode/RegisterCode';
import Contact from './Pages/Contact/Contact';
import Policy from './Pages/Policy/Policy';
import login from './Auth/LogIn';
import AdminPage from './Auth/Admin/AdminPage';
import Logout from './Auth/Logout';
import Winner from './Pages/Winners/Winners';
import Register from './Auth/Register';
import Navbar from './Navbar';

export default class Context extends Component {
        
    render() {
        return (<div>

            <Layout>
                <Router>

                    <Navbar />

                    <Switch>
                        <Route exact path="/" component={Home}>
                        </Route>

                        <Route exact path="/Contact" component={Contact}>
                        </Route>

                        <Route path="*" component={NotFound} />

                    </Switch>
                </Router>

            </Layout>
        </div>)
    }
}